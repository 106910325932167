.events-list {
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    background: #161616;
    overflow-y: scroll;
    height: calc(100vh - 56px - 56px); /* Account for navbar and timeline */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.events-list-empty {
    align-content: center;
    justify-content: center;
}

.empty-list-placeholder {
    display: block;
}

.empty-list-placeholder-main {
    margin-bottom: 16px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #F4F4F4;
    --cds-layer-accent: #161616;
    --cds-interactive: #FF832B;
}

.empty-list-placeholder-main-child {
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
}

.empty-list-placeholder-sub {
    width: 336px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.32px;
    text-align: center;
    color: #C6C6C6;
}

.events-list::-webkit-scrollbar {
    display: none; /* Chrome, Safari and Opera */
}