.event-popup .leaflet-popup-content-wrapper {
    border-radius: 0px;
}

.leaflet-popup-content {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 16pxpx;
    margin-bottom: 16px;
}

a.leaflet-popup-close-button {
    display: none;
}

.event-popup-content {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
}

.event-popup-row {
    margin-left: 16px;
    margin-right: 16px;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: auto auto;
    column-gap: 24px;
}

.event-popup-key {
    align-self: start;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    align-items: left;
    text-align: left;
    letter-spacing: 0.32px;
    color: #161616;
}

.event-popup-value {
    align-self: end;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    align-items: right;
    text-align: right;
    letter-spacing: 0.32px;
    color: #161616;
}

.event-popup-header {
    padding-bottom: 16px;
}

.event-popup-footer {
    padding-top: 16px;
}

.event-popup-footer a {
    text-decoration: none;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    align-items: center;
    letter-spacing: 0.32px;
    color: #FF832B;
}

.event-popup-title {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    align-items: center;
    letter-spacing: 0.32px;
    color: #161616;
}