.filterbox {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 0;
}

.filterbox .remove-button {
    margin: 8px 0 0 16px;
    cursor: pointer;
    padding: 0;
}

.filterbox-container {
    margin: 24px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow: hidden;
    align-items: flex-start;
    padding: 0;
    gap: 8px;
}

.filterbox-chip {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 8px;
    gap: 8px;
    height: 24px;
    background: #393939;
    border-radius: 12px;
    min-width: 20px;
}

.filterbox-chip:hover {
    opacity: 80%;
    cursor: pointer;
}

.filterbox-chip span.title {
    height: 16px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.32px;
    color: #F4F4F4;
    min-width: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.filterbox-chip span.text {
    height: 16px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.32px;
    color: #F4F4F4;
    min-width: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}