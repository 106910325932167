.pick-detail-data-container {
    padding: 24px;
    background: #161616;
    overflow-y: scroll;
    height: calc(100vh - 56px - 56px); /* Account for navbar and timeline */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 56px 1fr;
}

.pick-detail-data-container::-webkit-scrollbar {
    display: none; /* Chrome, Safari and Opera */
}

.breadcrumb-container {
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: start;
    margin: 0px;    /* vertical horizontal*/
    border-bottom: 1px solid #393939;
    position: relative;
}

.breadcrumb-container-internal {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-content: center;
    gap: 8px;
    flex-grow: 1;
}

.breadcrumb-container-internal * {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
}

.breadcrumb-item-rtd {
    color: #FF832B
}

.breadcrumb-item-separator {
    text-align: center;
    color: #6F6F6F;
}

.breadcrumb-item-pick-title {
    color: #F4F4F4;
}

.pick-detail-subtitle {
    grid-column: 1 / span 1;
    grid-row: 2 / span 1;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-items: start;
    letter-spacing: 0.16px;
    color: #C6C6C6
}

.pick-detail-loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-column: 1 / span 1;
    grid-row: 2 / span 1;
    --cds-layer-accent: #161616;
    --cds-interactive: #FF832B;
}

.pick-detail-loader-element {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #F4F4F4;
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
}

.data-list {
    grid-column: 1 / span 1;
    grid-row: 2 / span 1;
}

.data-detail-four-column {
    display: grid;
    row-gap: 8px;
    padding-left: 4px;
    padding-top: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid #393939;
    grid-template-columns: repeat(4, 1fr);
}

.data-detail-four-column:first-child {
    padding-top: 0;
}

.data-detail-four-column:last-child {
    padding-bottom: 0;
    border-bottom: 0;
}

.data-detail-label {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.32px;
    color: #6F6F6F;
}

.data-detail-value {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.32px;
    color: #F4F4F4;
}

.data-detail-subvalue {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.32px;
    color: #C6C6C6;
}

.chart-container {
    height: 264px;
    width: 164px;
    background: #161616;
}