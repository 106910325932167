.difference-time-component {
    padding-right: 24px;
    padding-left: 24px;
    padding-top: 18px;
    padding-bottom: 18px;
    border-left: 1px solid #393939;
    align-self: center;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.16px;
}

.difference-time-component-pre {
    color: #C6C6C6;
}

.difference-time-component-value {
    color: #F4F4F4;
}

.difference-time-component-value-warning {
    color: #F1C21B;
}

.difference-time-component-value-alert {
    color: #FF8389;
}

.difference-time-popup {
    right: 24px;
    top: 80px;
    width: 288px;
    z-index: 2000;
    position: absolute;
    padding: 16px;
    background: #F4F4F4;
    border-top: 1px solid;
    border-bottom: 1px solid;
    border-right: 1px solid;
    border-left: 4px solid;
    border-radius: 2px;
    display: grid;
    grid-template-columns: 1fr min-content;
    grid-template-rows: auto;
}

.difference-time-popup-warning {
    border-color: #F1C21B;
}

.difference-time-popup-alert {
    border-color: #FA4D56;
}

.difference-time-popup-header {
    margin-bottom: 8px;
}

.difference-time-popup-body {
    margin-bottom: 24px;
}

/* .difference-time-popup-footer {

} */

.difference-time-popup-header-value {
    align-self: start;
    align-items: right;
    text-align: right;
    padding-left: 8px;
}

.difference-time-popup-header-title {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    align-items: center;
    letter-spacing: 0.16px;
    color: #161616;
}

.difference-time-popup-body-value {
    grid-column: 1 / span 1;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: #161616;
}

.difference-time-popup-footer-value {
    grid-column: 1 / span 1;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: #161616;
}