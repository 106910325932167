.breadcrumb-buttons-container {
    display: flex;
    flex-direction: row;
}

.breadcrumb-icon-button {
    width: 40px;
    height: 40px;
    border-radius: 2px;
    padding: 8px;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: #FF832B;
    cursor: pointer;
}

.breadcrumb-icon-button:hover {
    background-color: #BA4E00;
}

.breadcrumb-loader {
    width: 40px;
    height: 40px;
    border-radius: 2px;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: #FF832B;
    --cds-layer-accent: #FF832B;
    --cds-interactive: #161616;
}