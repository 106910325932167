.interval-viewer-container {
    background: #262626;
    display: flex;
    align-items: center;
    justify-items: center;
    border-left: 1px solid #161616;
}

.interval-viewer {
    display: flex;
    align-items: center;
    margin-left: 8px;
    margin-right: 8px;
    height: fit-content;
    width: fit-content;
    padding: 8px 11px;
    letter-spacing: 0.16px;
    background: #262626;
    border: 1px solid #393939;
    border-radius: 2px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    white-space: pre;
    color: #F4F4F4;
    cursor: pointer;
}

.interval-viewer-active {
    border: 2px solid #FFFFFF;
    margin-left: 7px;
    margin-right: 7px;
}