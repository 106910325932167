.dropdown-menu-container {
    background-color: #262626;
    padding: 24px;
    color: #F4F4F4;
    width: 272px;
    height: max-content;
    margin-top: 8px;
    border: 1px solid #393939;
    border-radius: 2px;
}

.dropdown-menu-48 {
    z-index: 1000;
    position: absolute;
    top: 48px;
    right: 0px;
}

.dropdown-layers-overflowed {
    height: max-content;
    overflow-y: auto;
    padding-top: 24px;
    overflow-x: hidden;
}

.dropdown-label-toggle-container {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto auto auto auto;
    row-gap: 16px;
}

.drpdown-toggle-label {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 0.32px;
    color: #C6C6C6;
}