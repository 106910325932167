.stations-header-container {
    grid-row: 1 / span 1;
    grid-column: 1 / span 1;
    display: grid;
    grid-template-rows: 80px 56px;
    grid-template-columns: auto;
    z-index: 1000;
}

.stations-header-container-gradient {
    background: linear-gradient(to bottom, #161616, transparent);
}

.stations-header-container-no-gradient {
    background: #161616;
}

.stations-header-title {
    grid-row: 1 / span 1;
    display: grid;
    grid-template-columns: auto 1fr auto auto;
    grid-template-rows: auto;
    margin: 24px 24px 0px 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid #393939;
    align-items: center;
}

.stations-header-sub {
    grid-row: 2 / span 1;
    margin: 16px 0px 24px 24px;
}

.stations-header-sub-text {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: #C6C6C6;
}

.stations-header-sub-text-hl {
    color: #FF832B;
}

.stations-header-title-name {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #F4F4F4;
    padding: 4px;
}

.stations-header-title-cta {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: #FF832B;
    padding-right: 18px;
    cursor: pointer;
}

.stations-header-title-icon {
    padding-right: 4px;
    cursor: pointer;
}