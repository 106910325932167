.dropdown-date-picker-container {
    z-index: 1000;
    position: absolute;
    bottom: 52px;
    left: 202px;
    display: grid;
    grid-template-columns: 288px;
    grid-template-rows: 444px 72px;
    background: #262626;
    border: 1px solid #393939;
    border-radius: 2px;
}

.calendar-internal-container {
    display: grid;
    grid-template-columns: repeat(7, 40px);
    grid-template-rows: repeat(8, 40px) 104px;
    background: #262626;
    padding: 4px;
}

.calendar-button {
    grid-row: 1 / span 1;
    justify-self: stretch;
    align-self: stretch;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    align-items: center;
    justify-items: center;
}

.calendar-button-enabled {
    cursor: pointer;
}

.calendar-button-enabled:hover {
    background: #353535;
}

.calendar-button-disabled:hover {
    background: #262626;
}

.calendar-header {
    justify-self: stretch;
    align-self: stretch;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    align-items: center;
    justify-items: center;
    grid-column: 2 / span 5;
    grid-row: 1 / span 1;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: #F4F4F4;
}

.calendar-day-header-element {
    justify-self: stretch;
    align-self: stretch;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    align-items: center;
    justify-items: center;
    grid-row: 2 / span 1;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: #F4F4F4;
}

.calendar-element {
    justify-self: stretch;
    align-self: stretch;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    align-items: center;
    justify-items: center;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.16px;
}

.calendar-element-enabled {
    cursor: pointer;
    color: #F4F4F4;
}

.calendar-element-enabled:hover {
    background: #8A3800;
}

.calendar-element-disabled {
    cursor: default;
    color: #C6C6C6;
}

.calendar-element-selected {
    cursor: default;
    background: #FF832B;
    font-weight: 600;
}

.calendar-element-selected:hover {
    background: #FF832B;
}

.calendar-control-bar {
    justify-self: stretch;
    align-self: stretch;
    grid-row: 2 / span 1;
    grid-column: 1 / span ;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    padding: 16px;
    background: #393939;
}

.calendar-date-time-form {
    grid-row: 9 / span 1;
    grid-column: 1 / span 7;
    padding-left: 12px;
    padding-right: 12px;
    display: grid;
    gap: 8px;
    grid-template-columns: 43% 57%;
    grid-template-rows: 34% 66%;
}

.calendar-date-time-label {
    align-self: end;
    justify-self: start;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.32px;
    color: #C6C6C6;
}

.calendar-date-time-input {
    align-self: start;
    justify-self: start;
    padding-left: 12px;
    padding-right: 12px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.16px;
    color: #F4F4F4;
    background: #262626;
    border: 1px solid #393939;
    border-radius: 2px;
    height: 40px;
}

.calendar-date-time-input:focus-visible {
    outline-offset: 0px;
    outline: #FFFFFF solid 1px;
}

.calendar-date-time-input-error {
    outline-offset: 0px;
    outline: #FA4D56 solid 2px;
}