.tooltip-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
    gap: 8px;
    position: absolute;
    width: max-content;
    height: 32px;
    background: #F4F4F4;
    border-radius: 2px;
    z-index: 1000
}

.tooltip-fragment-prefix {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 0.32px;
    color: #161616;
}

.tooltip-fragment-value {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 0.32px;
    color: #161616;
}