.cds--dropdown {
    background-color: #262626;
    border: 1px solid #6F6F6F;
    border-radius: 2px;
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #F4F4F4;
    outline: none;
}

.cds--list-box__menu:not(:focus) {
    border: none;
}

.cds--list-box__menu:active,
.cds--list-box__menu:focus,
.cds--list-box__field:focus,
.cds--list-box__field:active {
    outline: none !important;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
    background-color: #393939;
    color: #F4F4F4;
}

.cds--list-box__menu {
    background-color: #262626;
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #F4F4F4;
    outline: none;
    margin-top: 8px;
    border: 1px solid #6f6f6f;
    border-radius: 2px;
    box-shadow: none !important;
    max-height: 13rem !important;
}

.cds--list-box--expanded {
    max-height: 13rem !important;
}

.cds--dropdown:hover {
    background-color: #393939;
}

.cds--list-box__menu-item__option {
    border-top: 0;
    color: #F4F4F4;
}

.cds--list-box__menu-item:active {
    border: 2px solid #ff832b;
    border-radius: 2px;
}

.cds--list-box__menu-item__selected-icon {
    height: 0;
}

.cds--list-box__label {
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #F4F4F4;
    white-space: normal;
}

.cds--list-box__menu-icon > svg {
    fill: #f4f4f4
}

.cds--list-box__menu-item:hover {
    background-color: #262626;
    color: #f4f4f4;
}

.cds--list-box__menu-item--active {
    background-color: #262626;
    color: #f4f4f4 !important;
}

.cds--list-box__menu-item--active .cds--list-box__menu-item__option {
    color: #f4f4f4;
}

.cds--list-box__menu-item:hover .cds--list-box__menu-item__option {
    color: #f4f4f4;
    background-color: #262626;
}