.notification-panel {
    width: 288px;
    position: fixed;
    z-index: 2000;
    top: 80px;
    right: 24px;
    border: 1px solid;
    border-left: 4px solid;
    padding: 16px;
    background-color: #F4F4F4;
    transition: right 0.8s ease-out; /* Animation */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .notification-panel.error {
    border-color: #DA1E28;
  }
  
  .notification-panel.warning {
    border-color: #F1C21B;
  }
  
  .notification-panel.success {
    border-color: #24A148;
  }
  
  .notification-panel .notification-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #161616;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: IBM Plex Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.16px;
  }
  
  .notification-panel button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
  }
  
  .notification-panel.icon {
    width: 20px;
    height: 20px;
  }
  
  .notification-panel .notification-body {
    margin-top: 8px;
    color: #161616;
    font-feature-settings: 'clig' off, 'liga' off;

    font-family: IBM Plex Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.16px;
  }
  
  .notification-panel .notification-timestamp {
    margin-top: 24px;
    font-size: 0.85em;
    color: #161616;
    font-feature-settings: 'clig' off, 'liga' off;

    font-family: IBM Plex Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.16px;
  }
  