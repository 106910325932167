.clock-component {
    margin-right: 24px;
    align-self: center;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.16px;
}

.clock-component-pre {
    color: #C6C6C6;
}

.clock-component-value {
    color: #F4F4F4;
}