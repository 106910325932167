.map-auto-center-button {
    width: 48px;
    height: 48px;
    border-radius: 2px;
    background-color: #161616;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
  }
  
  .map-auto-center-button:hover {
    background-color: #606060;
  }
  
  .map-auto-center-button svg {
    width: 100%;
    height: 100%;
  }
  
  .map-auto-center-tooltip {
    position: absolute;
    bottom: -50px;
    white-space: nowrap;
    left: 50%;
    transform: translateX(-50%);
    display: inline-flex;
    padding: 8px;
    align-items: center;
    gap: 8px;
    border-radius: 2px;
    background: #F4F4F4;
    color:#161616;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: IBM Plex Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.32px;
    opacity: 0;
    transition: opacity 0.2s;
    pointer-events: none;
    z-index: 1001;
  }
  
  .map-auto-center-button:hover .map-auto-center-tooltip {
    opacity: 1;
  }
  