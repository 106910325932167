.ee-table {
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    margin: 0 0 12px 0;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    background: #161616;
}

.ee-table::-webkit-scrollbar { /* Chrome, Safari and Opera */
    display: none;
}

.ee-table-loader {
    flex-basis: 100%;
    display: flex;
    flex-direction: column-reverse;
    flex-basis: 40%;
    align-self: center;
    justify-self: center;
    align-items: center;
    justify-items: center;
    --cds-layer-accent: #161616;
    --cds-interactive: #FF832B;
}

.ee-table-placeholder-container {
    display: flex;
    flex-direction: column-reverse;
    flex-basis: 40%;
    align-self: center;
    justify-self: center;
    align-items: center;
    justify-items: center;
}

.ee-table-placeholder-main {
    margin-bottom: 16px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #F4F4F4;
}

.ee-table-placeholder-sub {
    width: 336px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.32px;
    text-align: center;
    color: #C6C6C6;
}

.ee-table-header {
    width: 100%;
    display: grid;
    position: sticky;
    top: 0;
    padding-top: 8px;
    padding-bottom: 8px;
    background: #161616;
    border-bottom: 1px solid #393939;
}

.ee-table-header-sortable {
    cursor: pointer;
}

.ee-table-header-cell {
    grid-row: 1 / span 1;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}

.ee-table-header-text {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: #F4F4F4;
}

.ee-table-row {
    width: 100%;
    display: grid;
    padding-top: 24px;
    padding-bottom: 24px;
    background: #161616;
    border-bottom: 1px solid #393939;
}

.ee-table-row:hover {
    background: #353535;
}

.ee-table-row-cell {
    grid-row: 1 / span 1;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}

.ee-table-row-text {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.16px;
    color: #F4F4F4;
}