.event-detail-container {
    display: grid;
    grid-template-columns: 776px 1fr;
    grid-template-rows: 1fr 56px;
    column-gap: 24px;
    background: #161616;
}

.timeline-event-detail {
    grid-column: 1 / span 2;
    grid-row: 2 / span 1;
}