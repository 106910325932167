.station-detail-container {
    display: grid;
    grid-template-rows: 80px 362px 1fr;
    grid-template-columns: 1fr 1fr;
    column-gap: 24px;
    background: #161616;
    padding-left: 24px;
    padding-right: 24px;
}

.station-detail-map {
    grid-column: 2 / span 1;
    grid-row: 2 / span 1;
    margin-top: 24px;
}

.breadcrumb-container-station-detail {
    grid-column: 1 / span 2;
    grid-row: 1 / span 1;
    display: flex;
    flex-direction: row;
    align-content: center;
    flex-wrap: wrap;
    justify-content: start;
    gap: 8px;
    margin: 0px;    /* vertical horizontal*/
    border-bottom: 1px solid #393939;
    position: relative;
}

.breadcrumb-container-station-detail-internal {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-content: center;
    gap: 8px;
    flex-grow: 1;
}

.breadcrumb-container-station-detail-internal * {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
}

.breadcrumb-item-stations {
    color: #FF832B
}

.breadcrumb-item-separator {
    text-align: center;
    color: #6F6F6F;
}

.breadcrumb-item-station-name {
    color: #F4F4F4;
}

.station-info {
    grid-column: 1 / span 1;
    grid-row: 2 / span 1;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 80px 1fr 80px 1fr;
}

.station-info-title {
    text-transform: uppercase;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #393939;
    display: flex;
    align-items: flex-end;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: #F4F4F4;
}

.station-info-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 8px;
    border-bottom: 1px solid #393939;
}

.station-info-dr-title {
    display: flex;
    align-items: flex-end;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.32px;
    color: #6F6F6F;
}

.station-info-dr-value {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.32px;
    color: #F4F4F4;
}

.station-channels {
    grid-column: 1 / span 2;
    grid-row: 3 / span 1;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 80px 1fr;
}