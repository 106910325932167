.event-detail-data-container {
    padding: 24px;
    background: #161616;
    overflow-y: scroll;
    height: calc(100vh - 56px - 56px); /* Account for navbar and timeline */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 56px 56px 1fr;
}

.event-detail-data-container::-webkit-scrollbar {
    display: none; /* Chrome, Safari and Opera */
}

.breadcrumb-container {
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* align-content: center; */
    /* justify-content: start; */
    margin: 0px;    /* vertical horizontal*/
    border-bottom: 1px solid #393939;
    position: relative;
}

.breadcrumb-container-internal {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-content: center;
    gap: 8px;
    flex-grow: 1;
}

.breadcrumb-container-internal * {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
}

.breadcrumb-item-rtd {
    color: #FF832B
}

.breadcrumb-item-separator {
    text-align: center;
    color: #6F6F6F;
}

.breadcrumb-item-event-title {
    color: #F4F4F4;
}

.breadcrumb-cancelled {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 10px !important;
    line-height: 12px !important;
    display: flex;
    align-items: center;
    letter-spacing: 0.4px;
    color: #161616;
    background: #F4F4F4;
    padding: 4px;
    text-transform: uppercase;
    border-radius: 2px;
}

.event-detail-subtitle {
    grid-column: 1 / span 1;
    grid-row: 2 / span 1;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-items: start;
    letter-spacing: 0.16px;
    color: #C6C6C6
}

.event-detail-data-list {
    grid-column: 1 / span 1;
    grid-row: 3 / span 1;
}

.event-detail-data-loading {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #F4F4F4;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    --cds-layer-accent: #161616;
    --cds-interactive: #FF832B;
}

.event-detail-data-loading-child {
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
}

.data-detail-four-column {
    display: grid;
    row-gap: 8px;
    padding-left: 4px;
    grid-template-columns: repeat(4, 1fr);
}

.data-detail-label {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.32px;
    color: #6F6F6F;
}

.data-detail-value {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.32px;
    color: #F4F4F4;
}

.data-mag-value {
    border-radius: 2px;
    padding: 2px 4px;
}

.data-mag-value-primary {
    background: #EB6200;
    font-weight: 800;
}

.data-detail-value-small {
    font-size: 12px;
    line-height: 16px;
}

.data-detail-subvalue {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.32px;
    color: #C6C6C6;
}

.chart-container {
    height: 264px;
    width: 164px;
    background: #161616;
}