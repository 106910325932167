.navbar {
    grid-row: 1 / span 1;
    grid-column: 1 / span 2;
    z-index: 2000;
    display: grid;
    overflow: hidden;
    align-items: center;
    grid-template-columns: 56px 1fr auto auto auto;
    grid-gap: 0px;
    background: #262626;
}

.navbar h1 {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #F4F4F4;
    margin-left: 16px;
    align-self: center;
}
