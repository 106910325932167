.App {
  text-align: center;
}

body {
  overflow: hidden;
}

a {
  text-decoration: none;
  color: #FF832B;
}

th {
  background-color: #F4F4F4 !important;
  border-bottom: 1px solid #E0E0E0;
}

h2 {
  padding: 32px 0px 25px 0px;
  margin: 0px 16px 0 16px;
  border-bottom: 1px solid #E0E0E0;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #161616;
}

h3 {
  padding: 16px 0 8px 16px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.16px;
  color: #525252;
}

.cds--table-sort {
  background-color: #F4F4F4;
  border-bottom: 1px solid #E0E0E0;
  outline: none;
}

.cds--table-sort:hover {
  background-color: #F4F4F4;
}

.root {
  overflow: hidden;
}

.main-container {
  height: 100vh;
  width: 100vw;
  display: grid;
  overflow: hidden;
  grid-template-columns: 56px 1fr;
  grid-template-rows: 56px 1fr;
}

.main-content {
  grid-column: 2 / span 1;
  grid-row: 2 / span 1;
  display: grid;
  overflow: hidden;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}

.cds--text-input {
  background-color: #262626 !important;
  border: 1px solid #6F6F6F;
  border-radius: 2px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color:#F4F4F4;
}

.cds--text-input::placeholder {
  color: #6F6F6F;
}

.cds--text-input:disabled::placeholder {
  color: #6F6F6F !important;
  opacity: 1;
  border: 1px solid #6F6F6F !important;
}

.cds--text-input:focus, .cds--text-input:active {
  outline: none;
}

.cds--select-input:focus, .cds--select-input:active {
  outline: none;
}

.cds--tab-content:focus, .cds--tab-content:active {
  outline: none;
}

.cds--tabs .cds--tabs__nav-link:focus, .cds--tabs .cds--tabs__nav-link:active {
  outline: none;
}

.cds--table-sort:focus, .cds--table-sort:active {
  outline: none;
}

.cds--link:focus, .cds--link:active {
  outline: none;
}

/*:root {
  --cds-field: #fff;
  --cds-layer-01: var(--cds-field);
  --cds-code-02-font-family: 'IBM Plex Sans';
  --cds-code-02-font-weight: 400;
  --cds-button-primary: #FF832B;
  --cds-text-on-color: #fff;
  --cds-link-primary: #FF832B;
  --border-e0: #e0e0e0
}*/

.cds--text-input:disabled {
  outline: 2px solid transparent;
  outline-offset: -2px;
  border: 1px solid #6F6F6F;
  background-color: #393939;
  color: #6F6F6F;
  cursor: not-allowed;
  -webkit-text-fill-color: #6F6F6F;
}

/* it removes carbon's tooltip from buttons */
.cds--popover {
  display: none;
}