.fm-data-container {
    grid-column: 1 / span 3;
    grid-row: 1 / span 1;
}

.fm-data-title {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: #C6C6C6;
    text-transform: capitalize;
    margin-bottom: 16px;
}

.fm-data-section {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}

.fm-data-section-title {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: #C6C6C6;
    margin-bottom: 4px;
}

.fm-data-section-data {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 8px;
    row-gap: 4px;
    margin-bottom: 16px;
}

.fm-data-section-data-label {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.32px;
    color: #6F6F6F;
}

.fm-data-section-data-value {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.32px;
    color: #F4F4F4;
}


.fm-image-container {
    grid-column: 4 / span 1;
    grid-row: 1 / span 1;
    display: flex;
    align-items: center;
    justify-content: center;
}