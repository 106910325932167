.doublerowregion-container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    align-items:flex-start
}

.doublerowregion-container-single {
    display: grid;
    overflow: hidden;
    grid-template-rows: 1fr;
}

.doublerowregion-region {    
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.16px;
    color: #F4F4F4;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.doublerowregion-country {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.32px;
    color: #C6C6C6;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.h-36 {
    height: 36px;
}
