.dropdown-filter-menu-container {
    background-color: #fff;
    color: #F4F4F4;
    width: 472px;
    max-height: 712px;
    margin-top: 8px;
    border-radius: 2px;
    border: 1px solid #6F6F6F;
}

.dropdown-filters-menu-48 {
    z-index: 25;
    position: absolute;
    border-radius: 2px;
    top: 48px;
    right: 0;
    background-color: #262626;
    padding: 24px;
}

.mg-label-container {
    display: flex;
}

.mg-label {
    flex-grow: 1;
}

.overflowed {
    height: 424px;
    overflow-y: auto;
    padding-top: 24px;
    overflow-x: hidden;
}

.button-container {
    z-index: 3;
    display: flex;
    justify-content: space-between;
}

.button {
    min-width: 95px;
    height: 40px;
    text-transform: uppercase;
}

.remove-button {
    height: 40px;
    text-transform: uppercase;
}

option {
    background-color: #abc;
}

.bea-btn {
    position: static;
    background-color: #FF832B;
    box-shadow: none !important;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #161616;
    letter-spacing: 1.25px;
    padding: 12px !important;
    text-align: center !important;
    border-radius: 2px;
    text-align: center;
    padding: 12px;
    justify-content: center;
}

.bea-btn:hover {
    background-color: #FA6800;
}

.bea-btn:focus {
    background-color: #FF832B;
    border: 2px solid #FF832B;
    border-radius: 2px;
}

.bea-btn:active {
    background-color: #BA4E00 !important;
    border: 1px solid #FF832B;
}

.bea-btn-transparent {
    position: static;
    background-color: #00000000;
    color: #FF832B;
    box-shadow: none !important;
    border-radius: 2px;
    letter-spacing: 1.25px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    border-radius: 2px;
    text-align: center;
    padding: 12px;
    justify-content: center;
}

.bea-btn-transparent:hover {
    background-color: #393939;
    border-radius: 2px;
    color: #EB6200;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-align: center;
    padding: 12px;
    justify-content: center;
}

.bea-btn-transparent:focus {
    background-color: #00000000;
    border-radius: 2px;
    border: none;
    color: #FF832B;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-align: center;
    padding: 12px;
    justify-content: center;
}

.bea-btn-transparent.active{
    background-color: #C6C6C6;
    border: none;
    color: #BA4E00;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-align: center;
    padding: 12px;
    justify-content: center;
}

.bea-btn-transparent:active {
    background-color: #C6C6C6;
    border: none;
    color: #BA4E00;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-align: center;
    padding: 12px;
    justify-content: center;
}

.p-24 {
    padding-top: 24px;
    padding-bottom: 24px;
    position: relative;
}

.bea-btn-black {
    position: static;
    background-color: #6F6F6F;
    border-radius: 2px;
    box-shadow: none !important;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
    padding: 12px;
    align-items: center;
    letter-spacing: 1.25px;
    text-align: center;
    justify-content: center;
}

.bea-btn-black:hover {
    background-color: #4C4C4C;
    border-radius: 2px;
    box-shadow: none !important;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
    padding: 12px;
    align-items: center;
    letter-spacing: 1.25px;
    text-align: center;
    justify-content: center;
}

.bea-btn-black:focus {
    background-color: #6F6F6F;
    border: 2px solid #FF832B;
    color: #ffffff;
    border-radius: 2px;
    box-shadow: none !important;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    padding: 12px;
    align-items: center;
    letter-spacing: 1.25px;
    text-align: center;
    justify-content: center;
}

.bea-btn-black:active {
    background-color: #6F6F6F;
    border: 1px solid #FF832B;
    inset: 0;
    color: #ffffff;
    border-radius: 2px;
    box-shadow: none !important;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    padding: 12px;
    align-items: center;
    letter-spacing: 1.25px;
    text-align: center;
    justify-content: center;
}

.me-4 {
    margin-right: 4px;
}

.mb-48 {
    margin-bottom: 48px;
}

.ms-2 {
    margin-left: 2px;
}

.ms-4 {
    margin-left: 4px;
}

.mh-12 {
    margin-left: 12px;
    margin-right: 12px;
}

.divider {
    width: 100%;
    height: 1px;
    background-color: #e0e0e0;
}