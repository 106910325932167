.pick-detail-container {
    display: grid;
    grid-template-columns: 776px 1fr;
    grid-template-rows: 1fr 56px;
    column-gap: 24px;
    background: #161616;
}

.timeline-pick-detail {
    grid-column: 1 / span 2;
    grid-row: 2 / span 1;
}

.pick-detail-map {
    grid-column: 2 / span 1;
    grid-row: 1 / span 1;
}

.pick-dot-marker {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    display: inline-block;
}