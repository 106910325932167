.station-popup .leaflet-popup-content-wrapper {
    border-radius: 0px;
}

.leaflet-popup-content {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 16pxpx;
    margin-bottom: 16px;
}

a.leaflet-popup-close-button {
    display: none;
}

.station-popup-content {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    background: #F4F4F4;
}

.station-popup-row {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: auto 1fr;
    column-gap: 24px;
}

.station-popup-event {
    align-items: center;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 16px;
    padding-right: 16px;
}

.station-popup-event:hover {
    background: #E8E8E8;
}

.station-popup-detail {
    margin-left: 16px;
    margin-right: 16px;
}

.station-popup-key {
    align-self: left;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: left;
    letter-spacing: 0.32px;
    color: #161616;
}

.station-popup-value {
    align-self: end;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    letter-spacing: 0.32px;
    color: #161616;
}

.station-popup-dot {
    height: 12px;
    width: 12px;
    background-color: #bbb;
    border-radius: 50%;
}

.station-popup-info {
    margin-left: 16px;
    margin-right: 16px;
}

.station-popup-header {
    padding-bottom: 16px;
    margin-left: 16px;
    margin-right: 16px;
}

.station-popup-footer {
    padding-top: 16px;
    margin-left: 16px;
    margin-right: 16px;
}

.station-popup-footer a {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    align-items: center;
    letter-spacing: 0.32px;
    color: #FF832B;
}

.station-popup-title {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    align-items: center;
    letter-spacing: 0.32px;
    color: #161616;
}