.info-page-container {
    display: grid;
    grid-template-columns: 312px 1fr;
    grid-template-rows: 368px 1fr;
}

.info-page-header {
    grid-column: 1 / span 2;
    grid-row: 1 / span 1;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 54px;
    line-height: 64px;
    color: #F4F4F4;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 120px;
    padding-left: 120px;
}

.info-page-sidebar {
    grid-column: 1 / span 1;
    grid-row: 2 / span 1;
    background: #161616;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: #F4F4F4;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 8px;
    padding-top: 64px;
    padding-right: 120px;
}

.info-page-body {
    grid-column: 2 / span 1;
    grid-row: 2 / span 1;
    background: #161616;
    display: flex;
    flex-direction: column;
    padding-top: 64px;
    padding-right: 216px;
    height: calc(100vh - 56px - 368px);
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.info-page-body::-webkit-scrollbar { /* Chrome, Safari and Opera */
    display: none;
}

.info-page-body-item {
    padding-top: 40px;
    padding-bottom: 40px;
    border-bottom: 1px solid #393939;
}

.info-page-body-item:first-child {
    padding-top: 0;
}

.info-page-body-item:last-child {
    border-bottom: 0;
}

.info-page-body-item-title {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 36px;
    color: #F4F4F4;
    margin-bottom: 16px;
}

.info-page-body-item-content {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #C6C6C6;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
}