.icon-button {
    justify-self: stretch;
    align-self: stretch;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    align-items: center;
    justify-items: center;
    cursor: pointer;
    height: 56px;
    width: 56px;
}

.icon-button:hover {
    background: #353535;
}