.filters-icon {
    height: 40px;
    width: 40px;
    padding: 0!important;
    border: none!important;
}

.filters-icon:hover {
    opacity: 80%;
}

.filters-icon:focus {
    outline: none!important;
}
