.cds--date-picker.cds--date-picker--single .cds--date-picker__input {
    width: 200px;
    background-color: #262626;
    color: #F4F4F4;
}

.cds--date-picker__input {
    border: 1px solid #6F6F6F;
    border-radius: 2px;
    background-color: #262626;
    color: #F4F4F4;
}

.cds--date-picker__input:disabled {
    border: 1px solid #6F6F6F;
    border-radius: 2px;
    background-color: #262626;
    color: #6F6F6F;
}

.cds--date-picker__input:focus, .cds--date-picker__input.cds--focused {
    outline: none;
    background-color: #262626;
    color: #F4F4F4;
}

.flatpickr-day.selected {
    background-color: #FF832B;
    color: #262626;
}

.flatpickr-day.today {
    position: relative;
    color: #FF832B;
    font-weight: 600;
}

.flatpickr-day.today.selected {
    outline: 2px solid #FF832B;
    outline-offset: -2px;
    color: #F4F4F4;
}

.flatpickr-day.today::after {
    position: absolute;
    bottom: 0.4375rem;
    left: 50%;
    display: block;
    width: 0.25rem;
    height: 0.25rem;
    background-color: var(--cds-link-primary, #FF832B);
    content: "";
    transform: translateX(-50%);
}

.cds--date-picker__icon {
    position: absolute;
    z-index: 1;
    top: 50%;
    right: 1rem;
    fill: var(--cds-icon-primary, #f4f4f4);
    pointer-events: none;
    transform: translateY(-50%);
}

.flatpickr-calendar.open {
    margin-top: 8px;
    border-radius: 2px;
    box-shadow: none;
    border: 1px solid #6F6F6F;
    background-color: #262626;
    color: #F4F4F4;
}

.flatpickr-day {
    font-size: var(--cds-body-compact-01-font-size, 0.875rem);
    font-weight: var(--cds-body-compact-01-font-weight, 400);
    line-height: var(--cds-body-compact-01-line-height, 1.28572);
    letter-spacing: var(--cds-body-compact-01-letter-spacing, 0.16px);
    display: flex;
    width: 2.5rem;
    height: 2.5rem;
    align-items: center;
    justify-content: center;
    color: #f4f4f4;
    cursor: pointer;
    transition: all 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
}

.flatpickr-day:hover {
    background: #393939;
}

.flatpickr-month {
    font-size: var(--cds-heading-compact-01-font-size, 0.875rem);
    font-weight: var(--cds-heading-compact-01-font-weight, 600);
    line-height: var(--cds-heading-compact-01-line-height, 1.28572);
    letter-spacing: var(--cds-heading-compact-01-letter-spacing, 0.16px);
    display: flex;
    height: 2.5rem;
    align-items: center;
    background-color: transparent;
    color: var(--cds-text-primary, #F4F4F4);
    line-height: 1;
    text-align: center;
}
.flatpickr-weekday {
    font-size: var(--cds-body-compact-01-font-size, 0.875rem);
    font-weight: var(--cds-body-compact-01-font-weight, 400);
    line-height: var(--cds-body-compact-01-line-height, 1.28572);
    letter-spacing: var(--cds-body-compact-01-letter-spacing, 0.16px);
    flex: 1 1;
    color: var(--cds-text-primary, #F4F4F4);
    cursor: default;
}

.flatpickr-next-month svg {
    fill: #F4F4F4;
}

.flatpickr-prev-month svg {
    fill: #F4F4F4;
}

.flatpickr-calendar.open:focus {
    outline: none;
}

.cds--date-picker__input:disabled {
    border: 1px solid #6F6F6F;
    background-color: #262626;
    color: #6F6F6F !important;
    cursor: not-allowed;
}

.cds--date-picker__input:disabled:hover {
    border: 1px solid #6F6F6F;
}

.cds--label {
    color: #f4f4f4
}

.cds--date-picker__input::placeholder {
    color: #6F6F6F;
    opacity: 1;
}

.cds--date-picker__input:disabled::placeholder {
    color: #6F6F6F;
}



.numInputWrapper .numInput {
    display: inline-block;
    width: 100%;
    padding: 0.25rem;
    border: none;
    margin: 0;
    -moz-appearance: textfield;
    color: #f4f4f4;
    background-color: #262626;
    cursor: default;
    font-family: inherit;
    font-size: inherit;
    font-weight: 600;
}

.numInputWrapper .arrowUp:hover::after,
.numInputWrapper .arrowDown:hover::after {
    border-top-color: #f4f4f4;
    border-bottom-color: #f4f4f4;
}
.numInputWrapper .arrowUp::after,
.numInputWrapper .arrowDown::after {
    border-top-color: #6f6f6f;
    border-bottom-color: #6f6f6f;
}

.numInputWrapper .arrowUp:active::after,
.numInputWrapper .arrowDown:active::after {
    border-top-color: #6f6f6f;
    border-bottom-color: #6f6f6f;
}