.offline-banner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-left: 16px;
    gap: 16px;
    border-left: 3px solid #EB6200;
    position: absolute;
    width: 276px;
    height: 48px;
    left: 50%;
    top: 80px;
    z-index: 1000;
    background: #262626;
}

.offline-banner > :nth-last-child(2) {
    margin-right: auto;
}

.offline-banner-message {
    font-family: IBM Plex Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.1599999964237213px;
    text-align: left;
    color: #F4F4F4;
}

.offline-banner-dismiss {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    width: 48px;
    height: 48px;
    background: #262626;
}

.offline-dismiss-icon {
    cursor: pointer;
    transition: color 0.3s; /* Add transition property for smooth color change */
    fill: #F4F4F4;
}

.offline-dismiss-icon:hover {
    fill: #EB6200;
}
