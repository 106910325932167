.eqlist {
    height: 26px;
    display: grid;
    grid-template-columns: 10% 10% 5% 1fr 10% 7% 8% 8% 8%;
    column-gap: 24px;
}

.row-eqlist {
    display: grid;
    grid-template-columns: 10% 10% 5% 1fr 10% 7% 8% 8% 8%;
    column-gap: 24px;
    padding: 24px 0 24px 0;
    cursor: pointer;
    border-bottom: 1px solid #393939;
}

.header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 0;
}

.empty-header-container {
    height: 24px !important;
}

.sortable {
    cursor: pointer;
} 

.header-text {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: #f4f4f4;
    flex-grow: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 4px;
}

.row-eqlist:hover {
    background-color: #262626;
}

.border-bottom {
    border-bottom: 1px solid #E0E0E0;
}

.single-row-container {
    display: flex;
    flex-direction: row;
    overflow: hidden;    
}

.single-row-content {    
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.16px;
    color: #F4F4F4;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.preferred {
    border-left: 4px solid #FF832B;
}

.ellipse {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}