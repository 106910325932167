.picks-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 80px 56px 1fr 56px;
    background: #161616;
}

.timeline-picks {
    grid-column: 1 / span 1;
    grid-row: 4 / span 1;
}

.picks-title {
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
    margin: 24px 24px 0px 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid #393939;
    align-items: center;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #F4F4F4;
    padding: 4px;
}

.picks-summary {
    grid-column: 1 / span 1;
    grid-row: 2 / span 1;
    margin: 16px 0px 24px 24px;
}

.picks-summary-text {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: #C6C6C6;
}

.picks-summary-text-hl {
    color: #FF832B;
}