.events-navbar-container {
    padding-bottom: 16px;
    border-bottom: 1px solid #393939;
    display: flex;
    justify-content: space-between;
}

.navbar-title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
    gap: 8px;
}

.navbar-title {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #F4F4F4;
}