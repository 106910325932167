.decision-table-container {
    display: grid;
    row-gap: 2px;
    column-gap: 24px;
    grid-template-columns: repeat(4, 1fr);
}

.decision-table-values-container {
    display: grid;
    row-gap: 2px;
    column-gap: 2px;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    grid-template-rows: repeat(8, minmax(0, 1fr));
}

.decision-table-labels-container {
    display: grid;
    row-gap: 2px;
    column-gap: 2px;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-template-rows: repeat(8, minmax(0, 1fr));
}

.decision-table-label {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-self: right;
    letter-spacing: 0.32px;
    color: #8D8D8D;
    padding-top: 8px;
    padding-bottom: 8px;
}

.decision-table-value-title {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 0.32px;
    text-transform: uppercase;
    color: #C6C6C6;
    padding: 8px;
    background: #262626;
}

.decision-table-value {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.32px;
    color: #161616;
    padding: 8px;
}