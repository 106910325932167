.pagination-footer {
    width: calc(100vw - 56px);
    background-color: #262626;
    z-index: 24;
    height: 48px;
    display: grid;
    grid-template-columns: auto auto 1fr auto auto;
    padding: 8px;
}

.pagination-label {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 0.32px;
    color: #C6C6C6;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.pages-count {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.32px;
  color: #F4F4F4;
}

.pages-count-selection {
  display: flex;
}

.vertical-divider {
  margin-left: 16px;
  margin-right: 16px;
  border-left: 1px solid #E0E0E0;
  width: 0px;
}

.vertical-divider-8 {
  margin-left: 8px;
  margin-right: 8px;
  border-left: 1px solid #E0E0E0;
  width: 0px;
}

.pages-handler {
  min-width: 201px;
}

.ml-8 {
  margin-left: 8px;
}

.mr-16 {
  margin-right: 16px;
}

.page-swipe-button {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
}

.page-swipe-button:hover {
  cursor: pointer;
  background-color: #393939;
}

/* DROPDOWN */
.pagination-dropdown {
    min-width: 35px;
    position: relative;
    margin-left: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    gap: 4px;
}

svg.arrows {
    fill: #e0e0e0 !important;
    color: #e0e0e0;
}

/* pagination-Dropdown Content (Hidden by Default) */
.pagination-dropdown-content {
  display: none;
  position: absolute;
  left: 0;
  background: #262626;
  border: 1px solid #6F6F6F;
  border-radius: 2px;
  min-width: 50px;
  box-sizing: border-box;
  z-index: 33;
  max-height: 204px;
  overflow: hidden auto;
}

/* Links inside the pagination-dropdown */
.pagination-dropdown-content div {
  color: #e0e0e0;
  padding: 12px 16px;
  display: block;
}

.pagination-dropdown:hover {
  cursor: pointer;
}

/* Change color of pagination-dropdown links on hover */
.pagination-dropdown-content div:hover {
    background-color: #e5e5e5;
    cursor: pointer;
}
  
/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {
  display:block;
}