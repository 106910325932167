.dashboard-map {
    grid-column: 2 / span 1;
    grid-row: 1 / span 1;
}

.leaflet-control-zoom-in {
    width: 40px !important;
    height: 40px !important;
    background-color: #161616 !important;
    color: #FFFFFF !important;
    background: #161616 !important;
    border-radius: 2px;
}

.leaflet-control-zoom-out {
    width: 40px !important;
    height: 40px !important;
    background-color: #161616 !important;
    color: #FFFFFF !important;
    background: #161616 !important;
    border-radius: 2px;
}

.dot-marker {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    display: inline-block;
}

.dot-marker-secondary {
    opacity: 0.2;
}

.dot-marker-recent {
    display: inline-block;
    background: #F4F4F4;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 2px solid #393939;
}

.default-tooltip-no-show {
    visibility: hidden;
}

.default-tooltip-no-show > * {
    visibility: visible;
}

.recent-marker-tooltip {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
    gap: 8px;
    position: absolute;
    width: max-content;
    height: 32px;
    background: #F4F4F4;
    border-radius: 2px;
    z-index: 1000
}

.recent-marker-tooltip-prefix {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 0.32px;
    color: #161616;
}

.recent-marker-tooltip-value {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 0.32px;
    color: #161616;
}

.map-layer-control {
    position: absolute;
    top: 80px; /* This is 24 + 56 (the height of the navbar) */
    right: 24px;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.map-coordinates-box {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px;
    gap: 8px;
    position: absolute;
    bottom: 80px; /* This is 56 + 24 (timeline, margin) */
    z-index: 1000;
    background: #161616;
}

.map-coordinates-box-label {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 0.32px;
    color: #C6C6C6;
}

.map-coordinates-box-value {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 0.32px;
    color: #F4F4F4;
}

.map-coordinates-box-dash {
    left: 496px; /* This is 56 + 416 + 24 (sidebar, event panel, margin) */
}

.map-coordinates-box-event {
    left: 880px; /* This is 56 + 776 + 24 + 24 (sidebar, event panel, column gap, margin) */
}