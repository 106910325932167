.events-container {
    display: grid;
    max-width: 100vw;
    max-height: calc(100vh - 56px);
    grid-template-columns: 100vw;
    grid-template-rows: 1fr 48px;
    background: #161616;
    padding: 0;
    position: relative;
}

.events-content {
    padding: 24px 24px 0 24px;
    display: grid;
    max-width: 100vw;
    grid-template-columns: calc(100vw - 24px - 24px - 56px);
    grid-template-rows: auto 1fr;
}

.events-table-container {
    display: grid;
    width: 100vw;
    grid-template-columns: calc(100vw - 24px - 24px - 56px);
    grid-template-rows: 80px 1fr;
}

.events-pagination-container {
    position: fixed;
    bottom: 0;
}