.ctv-component {
    padding-right: 24px;
    padding-left: 24px;
    padding-top: 18px;
    padding-bottom: 18px;
    border-left: 1px solid #393939;
    align-self: center;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.16px;
}

.ctv-component-pre {
    color: #C6C6C6;
}

.ctv-component-value {
    color: #F4F4F4;
}