.event-card {
    position: relative;
    padding-top: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid #393939;
    width: 416px;
    height: fit-content;
    cursor: pointer;
}

.event-card:hover {
    background: #353535
}

.new-tab-icon {
    position: absolute;
    top: 8px;
    right: 16px;
    width: 24px;
    height: 24px;
    fill: #C6C6C6; /* Initial color of the icon */
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease, fill 0.3s ease; /* Adjust transition */
}

.new-tab-icon:hover {
    fill: #FF832B; /* Color of the icon on hover */
}

.event-card:hover .new-tab-icon {
    opacity: 1;
    visibility: visible;
}

.event-title {
    /* border-left: 4px solid #82CFFF; This is now set programmatically in the component */
    padding-left: 20px;
    margin-bottom: 24px;
}

.event-title > span {
    display: block;
}

.event-title-main {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #F4F4F4;
    padding-bottom: 8px;
    display: flex !important;
    align-items: center;
}

.event-title-sub {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: #C6C6C6;
}

.event-title-cancelled {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 10px !important;
    line-height: 12px !important;
    display: flex !important;
    align-items: center;
    letter-spacing: 0.4px;
    color: #161616;
    background: #F4F4F4;
    padding: 4px;
    text-transform: uppercase;
    border-radius: 2px;
    margin-left: 8px;
}

.event-summary {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-row-gap: 8px;
    padding-right: 24px;
    padding-left: 24px;
}

.event-summary-value {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 2px;
    padding: 2px 4px;
    color: #C6C6C6;
}

.event-summary-value-primary {
    background: #EB6200;
    font-weight: 800;
}

.event-summary-label {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.32px;
    color: #6F6F6F;
    border-radius: 2px;
    padding-right: 8px;
    padding-left: 4px;
    padding-top: 2px;
    padding-bottom: 2px;
}