.timeline {
    display: grid;
    grid-template-columns: repeat(6, auto) 1fr;
    grid-template-rows: 1fr;
    height: 56px;
}

.tl-button {
    padding: 22px;
    height: 56px;
    width: 56px;
    background: #262626;
    border-left: 1px solid #161616;
    cursor: pointer;
}

.tl-button:hover {
    background: #353535;
}

.tl-button-disabled:hover {
    background: #262626;
}

.tl-live-button-container {
    background: #262626;
    border-left: 1px solid #161616;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 12px;
    padding-bottom: 12px;
}

.tl-live-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 50px;
    height: 32px;
    background: #B81922;
    border-radius: 2px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.tl-live-button:hover {
    background: #DA1E28;
}

.tl-live-button-disabled {
    background: #606060;
}

.tl-live-button-disabled:hover {
    background: #6F6F6F;
}

.tl-live-button-deactivated {
    background: #606060;
    cursor: default;
}

.tl-live-button-deactivated:hover {
    background: #606060;
}

.tl-slot-container {
    grid-column: 7 / span 1;
    grid-row: 1 / span 1;
    border-left: 1px solid #161616;
    display: grid;
    grid-template-columns: 56px repeat(60, 1fr) 36px;
    grid-template-rows: repeat(3, 1fr);
    background: #262626;
}

.tl-slot-initial {
    background: #262626;
    grid-row: 1 / span 2;
    grid-column: 1 / span 1;
    border-right: 1px solid #6F6F6F;
}

.tl-slot-final {
    background: #262626;
    grid-row: 1 / span 2;
    grid-column: 62 / span 1;
    border-left: 1px solid #6F6F6F;
}

.tl-slot {
    background: #262626;
    grid-row: 1 / span 2;
    border-right: 1px solid #393939;
    display: grid;
    grid-template-rows: repeat(15, 1fr);
    grid-template-columns: 1fr;
    cursor: pointer;
}

.tl-slot:hover {
    background: #353535;
    border-right: 1px solid #F4F4F4;
    border-left: 1px solid #F4F4F4;
}

.tl-slot-current {
    background: #6F6F6F;
    /* border-right: 1px solid #F4F4F4; */
    border-left: 2px solid #F4F4F4;
}

.tl-slot-current:hover {
    border-left: 2px solid #F4F4F4;
}

.tl-slot-timebar {
    background: #262626;
    grid-row: 3 / span 1;
    grid-column: 1 / span 62;
    display: grid;
    grid-template-columns: 32px 64px 1fr 56px;
    align-items: center;
}

.tl-slot-timebar-time {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 10px;
    letter-spacing: 1.5px;
    color: #C6C6C6;
}

.tl-slot-timebar-begin {
    grid-column: 2 / span 1;
}

.tl-slot-timebar-end {
    grid-column: 4 / span 1;
}

.tl-slot-event-line {
    grid-column: 1 / span 1;
}