.dd-container {
    display: flex;
    flex-direction: column;
    position: relative;
}

.bc-button {
    background: #161616;
    border-radius: 2px;
    width: 48px;
    height: 48px;
    margin-left: 2px;
    margin-right: 2px;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {    
    display: none;
}

.dropdown:hover .dropdown-content {
    display: block;
}