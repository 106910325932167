.stations-map {
    grid-row: 1 / span 2;
    grid-column: 1 / span 1;
}

.leaflet-control-zoom-in {
    width: 40px !important;
    height: 40px !important;
    background-color: #161616 !important;
    color: #FFFFFF !important;
    background: #161616 !important;
    border-radius: 2px;
}

.leaflet-control-zoom-out {
    width: 40px !important;
    height: 40px !important;
    background-color: #161616 !important;
    color: #FFFFFF !important;
    background: #161616 !important;
    border-radius: 2px;
}

.map-layer-control {
    position: absolute;
    top: 80px; /* This is 24 + 56 (the height of the navbar) */
    right: 24px;
    z-index: 1000;
}