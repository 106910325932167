@use '@carbon/react';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.spacer-8{
  width: 8px;
  height: 8px;
}

.spacer-16{
  width: 16px;
  height: 16px;
}

.spacer-32{
  width: 32px;
  height: 32px;
}
.spacer-40{
  width: 40px;
  height: 40px;
}
.spacer-48{
  width: 48px;
  height: 48px;
}
.spacer-56{
  width: 56px;
  height: 56px;
}

.spacer-64{
  width: 64px;
  height: 64px;
}
.spacer-72{
  width: 72px;
  height: 72px;
}
.spacer-80{
  width: 80px;
  height: 80px;
}
.spacer-96{
  width: 96px;
  height: 96px;
}
.spacer-160{
  width: 160px;
  height: 160px;
}

.timeline {
  height: 56px;
  width: 100%;
}

// su FIGMA è chiamata navigation bar
.sidebar {
  position: absolute;
  left: 0;
  width: 56px;
  height: 100%;
}

.title {
  color: #F4F4F4;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
}

.subtitle {
  color: #f4f4f4;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
}

.mt-8 {
  margin-top: 8px;
}

.mb-8 {
  margin-bottom: 8px;
  color: #F4F4F4;
}

.mv-8 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.mt-16 {
  margin-top: 16px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mt-24 {
  margin-top: 24px;
}

.mv-16 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-32 {
  margin-bottom: 32px;
}

.me-2 {
  margin-right: 2px;
}

.me-4 {
  margin-right: 4px;
}

.ms-2 {
  margin-left: 2px;
}

.ms-4 {
  margin-left: 4px;
}

.mh-12 {
  margin-left: 12px;
  margin-right: 12px;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #393939;
}

.label {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #F4F4F4;
}

.input {
  width: 100%;
}

.earlyest-btn {
  background-color: #161616;
  box-shadow: none !important;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #F4F4F4;
  letter-spacing: 1.25px;
  padding: 12px !important;
  text-align: center !important;
  border-radius: 2px;
  text-align: center;
  padding: 12px;
  justify-content: center;
}

.earlyest-btn:hover {
  background-color: #353535;
}

.earlyest-btn:focus {
  background-color: #606060;
  border: 2px solid #606060;
  border-radius: 2px;
}

.earlyest-btn:active {
  background-color: #606060 !important;
  border: 1px solid #606060;
}

.earlyest-toggle .cds--toggle__switch {
  box-shadow: none !important;
}

.earlyest-toggle .cds--toggle__appearance {
  column-gap: 0;
}

.earlyest-btn-transparent {
  background-color: #00000000;
  color: #FF832B;
  box-shadow: none !important;
  border-radius: 2px;
  letter-spacing: 1.25px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  border-radius: 2px;
  text-align: center;
  padding: 12px;
  justify-content: center;
}

.earlyest-btn-transparent:hover {
  background-color: #e5e5e5;
  border-radius: 2px;
  color: #EB6200;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-align: center;
  padding: 12px;
  justify-content: center;
}

.earlyest-btn-transparent:focus {
  background-color: #00000000;
  border-radius: 2px;
  border: none;
  color: #FF832B;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-align: center;
  padding: 12px;
  justify-content: center;
}

.earlyest-btn-transparent:active {
  background-color: #C6C6C6;
  border: none;
  color: #BA4E00;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-align: center;
  padding: 12px;
  justify-content: center;
}

.p-24 {
  padding-top: 24px;
  padding-bottom: 24px;
  position: relative;
}

.earlyest-btn-black {
  background-color: #393939;
  border-radius: 2px;
  box-shadow: none !important;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  padding: 12px;
  align-items: center;
  letter-spacing: 1.25px;
  border-radius: 2px;
  text-align: center;
  padding: 12px;
  justify-content: center;
}

.earlyest-btn-black:hover {
  background-color: #4C4C4C;
  border-radius: 2px;
  box-shadow: none !important;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  padding: 12px;
  align-items: center;
  letter-spacing: 1.25px;
  border-radius: 2px;
  text-align: center;
  padding: 12px;
  justify-content: center;
}

.earlyest-btn-black:focus {
  background-color: #393939;
  border: 2px solid #FF832B;
  color: #ffffff;
  border-radius: 2px;
  box-shadow: none !important;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;  
  padding: 12px;
  align-items: center;
  letter-spacing: 1.25px;
  border-radius: 2px;
  text-align: center;
  padding: 12px;
  justify-content: center;
}

.earlyest-btn-black:active {
  background-color: #6F6F6F;
  border: 1px solid #FF832B;
  inset: 0;
  color: #ffffff;
  border-radius: 2px;
  box-shadow: none !important;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;  
  padding: 12px;
  align-items: center;
  letter-spacing: 1.25px;
  border-radius: 2px;
  text-align: center;
  padding: 12px;
  justify-content: center;
}

.p-24 {
  padding-top: 24px;
  padding-bottom: 24px;
  position: relative;
}

.preferred {
  width: 6px;
  height: 6px;
  margin-right: 4px;
  margin-top: 8px;
}

.cell-flex {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: #161616;
  display: flex;
}

.magnitudes-list {
  padding: 0px;
  margin: 0px;
  flex-direction: row;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 114px;
  height: 20px;
}