.accordion {
    background: #161616;
    border-top: 1px solid #393939;
    border-bottom: 1px solid #393939;
}

.accordion-title-bar {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 8px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #393939;
    cursor: pointer;
}

.accordion-title-bar:hover {
    background: #353535;
}

.accordion-title {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: #F4F4F4
}

.accordion-icon {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    align-items: center;
    justify-items: center;
    height: 24px;
    width: 24px;
}

.accordion-content {
    padding-top: 16px;
    padding-bottom: 24px;
}
