.sidebutton {
    justify-self: stretch;
    align-self: stretch;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    align-items: center;
    justify-items: center;
    border-bottom: 1px solid #161616;
    cursor: pointer;
}

.sidebutton:hover {
    background: #353535;
}

.sidebutton-selected {
    background: #161616;
}

.sidebutton-selected:hover {
    background: #161616;
}

.sidebutton-tooltip-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
    gap: 8px;
    position: absolute;
    width: max-content;
    height: 32px;
    background: #F4F4F4;
    border-radius: 2px;
    z-index: 1000
}

.sidebutton-tooltip-value {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 0.32px;
    color: #161616;
}