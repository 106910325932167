.picks-table {
    grid-column: 1 / span 1;
    grid-row: 3 / span 1;
    margin-left: 24px;
    margin-right: 24px;
    height: calc(100vh - 56px - 80px - 56px - 12px - 56px);
}

.picks-table-event-container {
    display: flex;
    align-items: center;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.16px;
    color: #F4F4F4;
}

.picks-table-event-dot {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 8px;
}

.picks-table-date-container {
    display: flex;
    flex-direction: column;
}

.picks-table-date {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.16px;
    color: #F4F4F4;
}

.picks-table-time {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.32px;
    color: #C6C6C6;
}