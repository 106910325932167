.latency-lt-30s {
    color: #6FDC8C;
}

.latency-lt-30s-tt {
    color: #24A148;
}

.latency-lt-5m {
    color: #FDDC69;
}

.latency-lt-5m-tt {
    color: #D2A106;
}

.latency-lt-1h {
    color: #FF832B;
}

.latency-lt-1h-tt {
    color: #EB6200;
}

.latency-gt-1h {
    color: #BE95FF;
}

.latency-gt-1h-tt {
    color: #8A3FFC;
}

.latency-unknown {
    color: #8D8D8D;
}

.latency-text {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.16px;
}

.latency-chan-name {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.32px;
    color: #161616;
}