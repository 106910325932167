.circle-container {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.inner-circle {
    width: 24px;
    height: 24px;
    border: 6px solid #FFFFFF;
    border-radius: 12px;
    background: #FFD6E8;
}

.circle-container:hover .inner-circle {
    background: #F4F4F4;
}
